import { Pipe, PipeTransform } from '@angular/core';
import { format, parseISO } from 'date-fns';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: any): string {
    const parsedDate = parseISO(`1970-01-01T${value}`);
    return format(parsedDate, 'HH:mm');
  }

}
